import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Avatar as MuiAvatar,
} from "@material-ui/core";
import img15 from "../../../../../../../../assets/images/img15.png";
import services from "../../../../../../../services";

export default function Profile() {
  const history = useHistory();
  const [student, setStudent] = useState({});
  useEffect(() => {
    services.api.students
      .findOne(localStorage.getItem("student"))
      .then((data) => {
        setStudent(data.data.student);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return (
    <MuiGrid item={true} xs={12} sm={12} md={12} lg={8} xl={8}>
      <MuiPaper>
        <div className={"px-[30px]  py-[10px]"}>
          <MuiTypography className={"font-poppins font-light text-[16px]"}>
            {"Profile"}
          </MuiTypography>
        </div>
        <MuiDivider />
        <MuiGrid container={true} item={true} xs={12} className={"px-3 py-3"}>
          <MuiGrid
            item={true}
            xs={4}
            className={"px-4 d-flex justify-content-center"}
          >
            <MuiAvatar
              src={ 
                `https://eportal.parkbritishschool.com/api/uploads/images/profile/${student._id?.replaceAll(
                  "/",
                  "-"
                )}.jpg`
              }
              className={"h-[250px] w-[250px]"}
            />
          </MuiGrid>

          <MuiGrid item={true} xs={8}>
            <MuiTypography
              noWrap={true}
              className={"font-poppins font-light text-[18px] text-[grey]"}
            >
              {student._id}
            </MuiTypography>
            <MuiTypography
              noWrap={true}
              className={"font-poppins font-light text-[25px] text-capitalize"}
            >
              {`${student?.firstName || "N/A"} ${student?.lastName || "N/A"}`}
            </MuiTypography>
            <MuiTypography variant={"body1"}>{student.gender}</MuiTypography>
          </MuiGrid>
        </MuiGrid>
        <MuiButton
          className={"w-100"}
          onClick={() => {
            history.push(
              `/students/profile/${student._id?.replace(/\//g, "-")}`
            );
          }}
        >
          {"VIEW PROFILE"}
        </MuiButton>
      </MuiPaper>
    </MuiGrid>
  );
}
